@import '../../styles/reduction.scss';

.not-found {
  --not-found-img-width: 300px;
  --not-found-gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem 0;
  color: #8e8e8e;
  gap: var(--not-found-gap);
  text-align: center;
  @include breakpoint(md) {
    --not-found-img-width: 430px;
    --not-found-gap: 1rem;
  }
  img {
    width: var(--not-found-img-width);
  }
  h1 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    margin: unset;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
  }
}
