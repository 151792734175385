.cr-content {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 60px);
  min-height: 100vh;
  padding: 0;
  margin: 0;

  div.header {
    height: 170px;
  }
}

a,
b,
i,
u,
td,
th,
div,
span,
label,
input,
textarea,
select,
option,
button,
li,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'gt-walsheim';
}

body {
  overscroll-behavior-x: none;
  overflow-x: hidden;
  @include scrollbar-style;

  .overflow-hidden {
    overflow: hidden;
  }
}

.noclick {
  pointer-events: none;
}

// react notification
.notifications-wrapper {
  .notification {
    .order-schedule {
      flex-basis: 100%;
      color: $react-notification-text;
      padding-right: 10px;

      .orderNum {
        color: #0000ff;
      }
    }
  }
}

.cursor {
  cursor: pointer;
}
.noborder {
  border: none !important;
}

.fiscal-month {
  border: 1px solid #d7dae2;
  border-radius: 4px;
  height: 48px;
  margin-top: 10px;
  margin-right: 15px;
  padding: 5px;
}

.checkbox-label {
  cursor: pointer;
}

.iss-header-privacy {
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;

  svg {
    pointer-events: none;
    cursor: pointer;

    &.hide {
      display: none;
    }
  }
}

.privacy-on-filter {
  position: absolute;
  top: 40px;
  left: 60px;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(6px);
  z-index: 100;

  &.hide {
    display: none;
  }
}

.position-relative {
  position: relative;
}

.pr-3 {
  padding-right: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.float-right {
  float: right !important;
}

.p-2 {
  padding: 0.6rem !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}
