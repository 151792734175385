.modalForComment {
  // .modal-content {
  //     background-color: #e0e0e0;
  // }

  .btnClose {
    right: 10px;
    top: 10px;
    position: absolute;
    border: 3px solid #d4281c;
    border-radius: 50px;
    padding: 0;
    // padding: 5px;
    background-color: transparent;
    outline: none !important;
    width: 40px;
    height: 40px;

    opacity: 1;
    // padding: 1rem;
    margin: 0;

    svg {
      position: absolute;
      top: 8px;
      right: 7px;
    }
  }

  .modal-title {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-body {
    position: unset;
  }

  .region {
    margin-top: 10px;

    .region-check {
      > div {
        text-align: left;
      }

      > select {
        appearance: auto !important;
      }

      button {
        z-index: -1;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: left;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        // border: 1px solid #ced4da;
        // appearance: none;
        border-radius: 0.25rem;
        transition:
          border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
      }
    }
  }

  .store {
    margin-top: 10px;
    margin-bottom: 10px;
    .store-check {
      > div {
        text-align: left;
      }

      > select {
        appearance: auto !important;
      }
    }
  }

  .iss-button-red {
    position: relative;
    // margin-right: 40px;
  }
}

.error-fallback {
  text-align: center;
}
