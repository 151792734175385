@import '../../styles/dynamic/all.scss';
@import '../../styles/fonts/font.scss';

.input-field {
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormLabel-root {
    color: #8e8e8e !important;
    font-weight: 500 !important;
    @include font_gt_walsheim_medium;
    &.Mui-error {
      color: #8e8e8e !important;
    }
  }
  .Mui-readOnly {
    &::after {
      border-bottom: unset !important;
    }
  }
  .MuiInputBase-root {
    background: #fff !important;
    border: 1px solid #e7e7e7 !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    @include font_gt_walsheim_medium;

    &.Mui-error {
      border: 2px solid #d91734 !important;
    }
    .MuiFormHelperText-root {
      @include font_gt_walsheim_medium;
      color: #d91734 !important;
      font-size: 14px !important;
      svg {
        stroke: #d91734 !important;
        stroke-width: 0.5 !important;
      }
    }
    &::before {
      border-bottom: unset !important;
      content: unset !important;
    }
  }
  .valid {
    .MuiInputBase-root {
      font-weight: 500 !important;
      border-radius: 8px !important;
      border: 2px solid #107f47 !important;
      background: #fff !important;
    }
    .MuiFormHelperText-root {
      @include font_gt_walsheim_medium;
      color: #107f47 !important;
      font-size: 14px !important;
      svg {
        stroke: #107f47 !important;
        stroke-width: 0.5 !important;
      }
    }
  }
  .Mui-error::after {
    border-bottom-color: none !important;
  }
  .input-field-helper-text {
    display: flex;
    flex-direction: row;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
  &-date-calendar {
    .Mui-selected {
      background-color: #32bfaf !important;
    }
  }
}

.MuiInputBase-input.Mui-disabled {
  color: $amway-black;
  -webkit-text-fill-color: $amway-black !important;
  background: $color_gray_light_2;
  border-radius: 8px;
}

.MuiPickersCalendarHeader-root {
  color: #32bfaf !important;
  @include font_gt_walsheim_medium;
  svg {
    color: #32bfaf !important;
  }
}

.MuiDayCalendar-weekDayLabel {
  @include font_gt_walsheim_bold;
  font-weight: 700 !important;
  color: #2c2c2c;
  font-size: 16px;
}

.MuiPickersDay-root {
  @include font_gt_walsheim_medium;
  font-weight: 500 !important;
  font-size: 16px;
  color: #2c2c2c;
  width: 44px !important;
  height: 44px !important;
}

.MuiDateCalendar-root {
  width: 400px !important;
  height: 400px !important;
  max-height: 400px !important;
}

.MuiDayCalendar-weekDayLabel {
  width: 44px !important;
  height: 44px !important;
}

.MuiPickersCalendarHeader-root {
  @include font_gt_walsheim_medium;
  padding: 0 0 16px 0;
}

.MuiPickersSlideTransition-root {
  min-height: 280px !important;
}

.drawer-title {
  @include font_gt_walsheim_bold;
  font-size: 18px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.divider {
  border-bottom: 1px solid $color_gray_light_3;
  margin-bottom: 16px;
}

.drawer-burger-menu {
  background-color: white;
  z-index: 1;
  cursor: pointer;
  display: inline-block;
  width: auto;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  .bar {
    display: block;
    height: 2px;
    width: 20px;
    background: $color_primary;
    margin: 6px 0 6px 0;
    text-align: right;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.top,
    &.bottom {
      width: 15px;
      margin: 5px 0 0 5px;
    }

    .middle {
      margin: 0 auto;
    }
  }

  &.active {
    .bar.top,
    .bar.bottom {
      width: 20px;
    }

    .top {
      -webkit-transform: translateY(8px) rotateZ(45deg);
      -moz-transform: translateY(8px) rotateZ(45deg);
      -ms-transform: translateY(8px) rotateZ(45deg);
      -o-transform: translateY(8px) rotateZ(45deg);
      transform: translateY(8px) rotateZ(45deg);
    }

    .bottom {
      -webkit-transform: translateY(-8px) rotateZ(-45deg);
      -moz-transform: translateY(-8px) rotateZ(-45deg);
      -ms-transform: translateY(-8px) rotateZ(-45deg);
      -o-transform: translateY(-8px) rotateZ(-45deg);
      transform: translateY(-8px) rotateZ(-45deg);
    }

    .middle {
      width: 0;
    }
  }
}

.date-picker {
  div,
  input {
    cursor: pointer;
    &.Mui-disabled {
      cursor: unset;
    }
  }
}

.upload-file-icon {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  &-desc {
    @include font_gt_walsheim_medium;
    font-size: 14px;
    color: $amway-black;
    font-weight: 700;
  }
}

.upload-file {
  .MuiFilledInput-input {
    // cursor: pointer;
    background: transparent;
  }
  &-exist {
    .MuiInputBase-root {
      border: 2px solid #2c2c2c !important;
    }
  }
}
