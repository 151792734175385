@import '../../styles/reduction.scss';

.sign-in {
  --sign-in-logo-width: 120px;

  @include breakpoint(lg) {
    --sign-in-logo-width: 150px;
  }

  &__header {
    display: flex;
    justify-content: center;
    padding: 2rem;
    img {
      width: var(--sign-in-logo-width);
      height: auto;
    }
  }

  &__content {
    border-top: 1px solid #dadada;
    padding: 0 1rem;
    text-align: center;

    h1 {
      margin: 1.5rem 0;
      font-size: 1.875rem;
      font-weight: 700;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem 2rem;
      color: #fff;
      border-radius: 2rem;
      border: none;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
      max-width: 400px;
      margin: auto;
      width: 100%;
      cursor: pointer;
      @include box-shadow(0px 4px 4px rgba(44, 44, 44, 0.1));

      &:hover {
        opacity: 0.6;
      }

      > svg {
        margin-inline-end: 10px;
      }
    }

    hr {
      margin: 1rem auto;
      max-width: 475px;
      border-color: #dadada;
      border-top: none;
    }

    &-amwayId {
      display: flex;
      flex-direction: column;

      .amwayId {
        background-color: #0f1419;
      }
    }

    &-socmed {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .facebook {
        background-color: #1877f2;
      }

      .google {
        background-color: #ea4335;
      }
    }
  }
}

.acc-select {
  --acc-select-logo-width: 120px;
  --acc-select-header-height: 100px;

  @include breakpoint(lg) {
    --acc-select-logo-width: 150px;
  }

  &__header {
    display: flex;
    justify-content: center;
    padding: 2rem;
    height: var(--acc-select-header-height);

    img {
      width: var(--acc-select-logo-width);
      height: auto;
    }
  }
  &__content {
    border-top: 1px solid #dadada;
    min-height: calc(100vh - var(--acc-select-header-height) - 375px);
    padding: 3rem 1rem;
    text-align: center;

    &-card {
      width: 100%;
      max-width: 576px;
      margin: auto;
    }

    &-card-header {
      margin-bottom: 1rem;
    }

    &-card-item {
      border: 2px solid #e7e7e7;
      border-radius: 0.5rem;
      cursor: pointer;
      display: flex;
      gap: 1rem;
      text-align: start;
      padding: 1.75rem 1rem;
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    &-btn {
      margin-top: 2rem !important;
    }

    .name {
      color: #32bfaf;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0.25rem;
    }
    .aboId {
      color: #8e8e8e;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &__radio.Mui-checked {
    color: #32bfaf !important;
  }
}

.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #fafafa;
  &__card {
    width: 100%;
    max-width: 540px;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  &__btn {
    margin-top: 2rem !important;
  }
  &__create-account {
    margin-top: 1rem;
  }
  .otp {
    gap: 0.75rem;
  }
  .otp-input {
    @include breakpoint(md) {
      --otp-input-width: 60px;
    }
  }

  strong {
    font-weight: 700;
  }

  #password-strength-section {
    display: none;
    #password-strength-meter {
      display: flex;
      width: 100%;
      margin: 0.25em;
      .slot {
        height: 2px;
        background-color: gray;
        margin-right: 0.25em;
        flex-grow: 1;
      }
    }
    .weak {
      background-color: #d72842 !important;
    }
    .medium {
      background-color: #9d6b10 !important;
    }
    .strong {
      background-color: #127c48 !important;
    }
    .weak-text {
      color: #d72842 !important;
    }
    .medium-text {
      color: #9d6b10 !important;
    }
    .strong-text {
      color: #127c48 !important;
    }
  }
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #fafafa;
  &__card {
    width: 100%;
    max-width: 540px;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  &__btn {
    margin-top: 2rem !important;
  }
  &__create-account {
    margin-top: 1rem;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
}
