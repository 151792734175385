@font-face {
  font-family: 'gt-walsheim';
  src: url('../../assets/fonts/gt-walsheim-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'gt-walsheim-medium';
  src: url('../../assets/fonts/gt-walsheim-medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'gt-walsheim-bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/gt-walsheim-bold.ttf'), url('../../assets/fonts/EUDC.tte');
  src:
    url('../../assets/fonts/gt-walsheim-bold.ttf') format('truetype'),
    url('../../assets/fonts/KAI.tte') format('embedded-opentype'),
    url('../../assets/fonts/EUDC.tte') format('truetype'),
    url('../../assets/fonts/KAI.tte') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@mixin font_gt_walsheim {
  font-family: 'gt-walsheim';
  font-weight: 400;
}
@mixin font_gt_walsheim_medium {
  font-family: 'gt-walsheim-medium' !important;
  // font-family: "gt-walsheim";
  font-weight: 400 !important;
}
@mixin font_gt_walsheim_bold {
  font-family: 'gt-walsheim-bold' !important;
  // font-family: "gt-walsheim";
  font-weight: 700 !important;
}
