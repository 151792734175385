// Base Colors
$color_primary: #2c2c2c;
$color_white: #fff;
$color_black: #000;
$color_gray_dark: #2c2c2c;
$color_gray_light_1: #919191;
$color_gray_light_2: #f4f4f4;
$color_gray_light_3: #e4e4e4;
$color_gray_light_4: #949494;
$color_gray_light_5: #8e8e8e;
$color_gray_light_6: #b5b5b5;
$color_gray_light_7: #c7c7c7;
$color_gray_light_8: #dcdcdc;
$color_gray_light_9: #ddd;
$color_granite_gray: #5f6368;
$color_dim_gray: #707070;
$color_mobster: #605866;
$color_blue: #38539a;
$color_pink_dark: #fb5480;
$color_blue_light: #f1f2ff;
$color_pink: #fb5480;
$color_light_red: #fde9ff;
$color_red_dark: #7f3e3e;
$color_pink_light: #ffe9e9;
$color_pink_light1: #fff1e8;
$color_green_gray: #546223;
$color_arrow_box_shadow: #2c2c2c3d;
$color_green: #32bfaf;

// Common variables
$color_text: $color_gray_dark;
$color_border: $color_gray_light_3;
$color_error: #d91734;
$color_bg_otp: #edfed8;
$color_otp_border: #7fbc96;
$color_otp_input: #396e75;
$color_bg: #fafafa;
$color_warning: #a36a00;
$color_alert_notification: #fdf4f4;
$color_alert_notification_bg: #fff9f0;
$color_success_bg: #f0fae5;
$color_chips_bg: #fff6e8;
$color_alert_text: #bf6155;
$color_success: #107f47;

// Colors list
$colors: (
  primary: $color_primary,
  white: $color_white,
  black: $color_black,
  dark_gray: $color_gray_dark,
  light_gray_1: $color_gray_light_1,
  light_gray_2: $color_gray_light_2,
  light_gray_3: $color_gray_light_3,
  light_gray_4: $color_gray_light_4,
  light_gray_5: $color_gray_light_5,
  light_gray_7: $color_gray_light_7,
  light_gray_8: $color_gray_light_8,
  granite_gray: $color_granite_gray,
  dim_gray: $color_dim_gray,
  mobster: $color_mobster,
  dark_pink: $color_pink_dark,
  blue: $color_blue,
  light_blue: $color_blue_light,
  pink: $color_pink,
  alert_notification_bg: $color_alert_notification_bg,
  alert_notification: $color_alert_notification,
  alert_text: $color_alert_text,
);
