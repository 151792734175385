// colors

.bg-gradient-theme {
  background: linear-gradient(to bottom, #6a82fb, #fc5c7d);
}
.bg-gradient-theme-top {
  background: linear-gradient(to top, theme-color('primary'), theme-color('secondary'));
}
.bg-gradient-theme-left {
  background: linear-gradient(to left, theme-color('primary'), theme-color('secondary'));
}
.bg-gradient-theme-right {
  background: linear-gradient(to right, theme-color('primary'), theme-color('secondary'));
}
