@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Aligns Code vertically middle
@mixin align-middle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
@mixin display-table {
  display: table;
}
@mixin display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
@mixin box-sizing($sizing) {
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  box-sizing: $sizing;
}
@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  transform: rotate($deg);
}
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  transform: $transform;
}
@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin fallback-image {
  background: url(../../assets/image/fallbackImg.png) center no-repeat #fff;
}
