.tutorial-intro {
  .drawer-title {
    justify-content: center;
  }
  &__wrapper {
    padding: 0 1rem;
    text-align: center;
  }
  &__head {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
    line-height: 32px;
  }
  p {
    font-size: 1.125rem;
  }
}

.tutorial-display {
  &__wrapper {
    padding: 0 1rem;
    // min-height: 260px;
    text-align: center;
    position: relative;
  }
  &__image {
    max-width: 650px;
    max-height: 60vh;
    min-height: 260px;
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  &__lazy {
    min-height: 260px;
  }
  .loader {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .MuiDrawer-paperAnchorBottom {
    --drawer-width: unset;
    --drawer-height: unset;
    min-width: 360px;
    min-height: 360px;
  }
}
