.icon {
  cursor: pointer;
}

.cust-dropdown {
  .icon {
    transform: rotate(0);
    transition: all ease 0.3s;
  }

  &.active {
    .icon {
      transform: rotate(-180deg);
      transition: all ease 0.3s;
    }
  }
}

.nav-link {
  .icon {
    pointer-events: none;
  }
}

.burger-menu {
  position: absolute;
  background-color: white;
  right: -2px;
  top: -3px;
  z-index: 1;
  cursor: pointer;
  display: inline-block;
  width: auto;
  margin: 0 auto;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  .bar {
    display: block;
    height: 2px;
    width: 20px;
    background: $color_primary;
    margin: 6px 0 6px 0;
    text-align: right;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.top,
    &.bottom {
      width: 15px;
      margin: 5px 0 0 5px;
    }

    .middle {
      margin: 0 auto;
    }
  }

  &.active {
    .bar.top,
    .bar.bottom {
      width: 20px;
    }

    .top {
      -webkit-transform: translateY(8px) rotateZ(45deg);
      -moz-transform: translateY(8px) rotateZ(45deg);
      -ms-transform: translateY(8px) rotateZ(45deg);
      -o-transform: translateY(8px) rotateZ(45deg);
      transform: translateY(8px) rotateZ(45deg);
    }

    .bottom {
      -webkit-transform: translateY(-8px) rotateZ(-45deg);
      -moz-transform: translateY(-8px) rotateZ(-45deg);
      -ms-transform: translateY(-8px) rotateZ(-45deg);
      -o-transform: translateY(-8px) rotateZ(-45deg);
      transform: translateY(-8px) rotateZ(-45deg);
    }

    .middle {
      width: 0;
    }
  }
}
