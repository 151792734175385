.cr-pop-menu {
  position: fixed;
  top: 50px;
  left: 0px;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: #fff;
  z-index: 101;
  padding: 25px 8px;
  overflow: auto;
  border-top: 1px solid #d9d9d9;
  //GT Walsheim Pro

  .nav-item {
    height: 45px;

    .nav-link {
      height: 45px;
      color: $amway-black;
      font-family: 'gt-walsheim';
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  .dividing-line {
    border-bottom: 1px solid #d9d9d9;
    margin: 15px;
  }
}

.navItem {
  display: flex;
  height: 45px;
  color: #2c2c2c;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 45px;
  position: relative;
  cursor: pointer;

  a {
    flex: 1;
    display: flex;
    &:visited {
      color: #2c2c2c;
    }

    &:active {
      color: #2c2c2c;
    }

    &:hover {
      color: #2c2c2c;
    }

    a {
      padding-top: 0px;
    }
  }

  &.selected {
    background: $amway-blue;
    border-radius: 8px;
    color: #fff;

    &::before {
      content: ' ';
      position: absolute;
      background: $amway-blue;
      border-radius: 0px 2px 2px 0px;
      width: 2px;
      height: 40px;
      top: 5px;
      left: -24px;
    }

    a {
      color: #fff;

      &:visited {
        color: #fff;
      }

      &:active {
        color: #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .icon {
    align-items: center;
    display: inline-flex;
    img {
      display: block;
      margin: auto;
      max-width: 28px;
      max-height: 28px;
    }
  }

  .text {
    pointer-events: none;
    padding-left: 10px;
    flex: 1;
  }

  .langText {
    pointer-events: none;
    flex: 1;
  }

  .countryLangText {
    pointer-events: none;
    padding-left: 25px;
    flex: 1;
  }

  .arrow {
    width: 30px;
    height: 45px;
    cursor: pointer;

    img {
      width: 16px;
      height: 9px;
      pointer-events: none;
    }
    .expandIcon {
      transform: rotate(00deg);
      transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .edgeEnd {
      transform: rotate(180deg);
      transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .logout_icon {
    align-items: center;
    display: inline-flex;
    img {
      display: block;
      margin: auto;
      max-width: 16px;
      max-height: 16px;
    }
  }
}

.navbar-expand-lg .navbar-collapse {
  flex-grow: 1;
  align-items: center;
  @include breakpoint(lg) {
    display: flex !important;
    flex-basis: auto;
  }
}
