@import '../../styles/dynamic/all.scss';

.footer {
  padding: 15px;
  background-color: $amway-black;
  color: $amway-white;
  line-height: 1.5;
  font-size: 0.875rem;
  min-height: 305px;

  @include breakpoint(md) {
    padding: 24px 82px;
  }

  &__breadcrumb-wrapper {
    padding-bottom: 1rem;
    border-bottom: 1px solid $color_gray_light_5;
    li {
      color: $color_gray_light_6;
      &:nth-last-child(2),
      &:last-child {
        color: $amway-white;
      }
    }
    a {
      display: flex;
      align-items: center;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    @include breakpoint(md) {
      padding: 24px 0;
    }
  }

  &__list {
    min-width: 200px;
    &-wrapper {
      @include breakpoint(md) {
        display: flex;
      }
    }
    &-link {
      text-decoration: none !important;
      color: $amway-white;
      text-transform: uppercase;
      &:hover {
        opacity: 0.5;
      }
    }
    .category {
      color: $color_gray_light_6;
    }
    .socmed {
      display: flex;
      gap: 1rem;
    }
    p {
      margin: 1rem 0;
    }
    li {
      margin-top: 0.5rem;
      line-height: 1.5;
    }
  }

  &__logo {
    margin-top: 1rem;
    @include breakpoint(md) {
      padding: 1rem 0 3rem;
      margin-top: 0;
      text-align: end;
    }
    &-wrapper {
      width: 115px;
      display: flex;
      align-items: center;
      @include breakpoint(md) {
        justify-content: flex-end;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__copyright {
    @include breakpoint(md) {
      // padding: 0 24px;
      display: flex;
      justify-content: space-between;
    }
    p {
      margin-bottom: 1rem;
    }
    .tnc {
      color: $color_gray_light_6;
      margin-bottom: 0;
      > a {
        text-decoration: none !important;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
