@import '../../../styles/reduction.scss';
@import '../../../styles/fonts/font.scss';

.profile-info {
  &-drawer {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
  }

  &-terms {
    @include font_gt_walsheim;
    a {
      color: #32bfaf;
      cursor: pointer;
    }
  }
}
