// Common Variables
$grid_spacing: 16px;
$half_spacing: 8px;
$gutter_lg: 50px;
$horizontal_grid_spacing: 5px;
$max_container_width: 1440px;

// Font-size
$font-10px: 0.625rem;
$font-11px: 0.6875rem;
$font-12px: 0.75rem;
$font-13px: 0.8125rem;
$font-14px: 0.875rem;
$font-15px: 0.9375rem;
$font-16px: 1rem;
$font-17px: 1.0625rem;
$font-18px: 1.125rem;
$font-19px: 1.1875rem;
$font-20px: 1.25rem;
$font-21px: 1.3125rem;
$font-22px: 1.375rem;
$font-23px: 1.4375rem;
$font-24px: 1.5rem;
$font-25px: 1.5625rem;

// Theme Color
$amway-white: #ffffff;
$amway-black: #2c2c2c;
$amway-blue: #1e247f;
$amway-dark-purple: #38539a;
